<div *ngIf="filtersEnabled" class="border-b-ui-gray-light border-b flex items-center justify-between">
  <mat-button-toggle-group
    class="desktop-toggle-group"
    name="incident-status"
    aria-label="Incident Status"
    [value]="incidentsFilterService.incidentTabStatusFilter"
    (change)="incidentsFilterService.incidentTabStatusFilter = $event.value">
    <mat-button-toggle [value]="incidentTabStatusFilterEnum.OPEN" class="test-incident-status-open">
      <span class="item-status-title">Open</span>
      <span class="incidents-amount-indicator mr-1" *ngIf="incidentsFilterService.newIncidentsAmount">{{
        incidentsFilterService.newIncidentsAmount
      }}</span>
      <span
        class="incidents-amount-indicator bg-ui-yellow-100 text-ui-yellow-350"
        *ngIf="incidentsFilterService.inProgressIncidentsAmount"
        >{{ incidentsFilterService.inProgressIncidentsAmount }}</span
      >
    </mat-button-toggle>
    <mat-button-toggle [value]="incidentTabStatusFilterEnum.HISTORY" class="test-incident-status-history">
      <span class="item-status-title">History</span>
    </mat-button-toggle>
  </mat-button-toggle-group>
  <div *ngIf="filtersEnabled && incidentsFilterService.incidentsData.length" class="flex justify-center">
    <app-search-input
      placeholder="Enter Ticket №"
      (searchDataEvent)="incidentsFilterService.ticketNumberSearch($event)" />
  </div>
</div>

<div
  class="test-open-incidents-filters flex items-start justify-between mt-5"
  *ngIf="
    incidentsFilterService.incidentTabStatusFilter === incidentTabStatusFilterEnum.OPEN &&
    incidentsFilterService.incidentsData.length &&
    filtersEnabled
  ">
  <div class="flex flex-wrap">
    <app-multiple-select
      [value]="incidentsFilterService.deviceTypeValue"
      [options]="incidentsFilterService.deviceTypeOptions"
      label="Device Type"
      selectClass="stroked-select-multiple"
      panelClass="device-type-overlay"
      class="mr-2"
      (selectDataEvent)="incidentsFilterService.multiSelectChange('deviceTypeValue', $event)"></app-multiple-select>

    <!-- <app-multiple-select
        [value]="assignedUserValue"
        [options]="assignedUserOptions"
        label="Assigned User"
        selectClass="stroked-select-multiple"
        panelClass="assigned-user-overlay"
        class="mr-2"
        (selectDataEvent)="multiSelectChange('assignedUserValue', $event)"></app-multiple-select> -->

    <app-multiple-select
      [value]="incidentsFilterService.statusFilter"
      [options]="incidentsFilterService.statusOptions"
      label="Status"
      selectClass="stroked-select-multiple"
      panelClass="status-overlay"
      class="mr-2"
      (selectDataEvent)="incidentsFilterService.multiSelectChange('statusFilter', $event)"></app-multiple-select>

    <mat-form-field class="stroked-select-single mr-2 mb-5" *ngIf="!incidentsFilterService.isBuildingTable">
      <mat-label>Building</mat-label>
      <mat-select [(ngModel)]="incidentsFilterService.selectedBuilding" panelClass="single-select-overlay">
        <mat-option
          *ngFor="let building of incidentsFilterService.buildingOptions | sort : 'asc' : 'title' : 'all'"
          [value]="building.value">
          {{ building.title }}
        </mat-option>
      </mat-select>
      <mat-icon matSuffix class="arrow-down">keyboard_arrow_down</mat-icon>
      <mat-icon matSuffix class="arrow-up">keyboard_arrow_up</mat-icon>
    </mat-form-field>

    <mat-form-field
      class="stroked-select-single mr-2 mb-5"
      *ngIf="incidentsFilterService.selectedBuilding && !incidentsFilterService.isFloorTable">
      <mat-label>Floor</mat-label>
      <mat-select [(ngModel)]="incidentsFilterService.selectedFloor" panelClass="single-select-overlay">
        <mat-option
          *ngFor="let floor of incidentsFilterService.floorOptions | sort : 'asc' : 'title' : 'all'"
          [value]="floor.value">
          {{ floor.title }}
        </mat-option>
      </mat-select>
      <mat-icon matSuffix class="arrow-down">keyboard_arrow_down</mat-icon>
      <mat-icon matSuffix class="arrow-up">keyboard_arrow_up</mat-icon>
    </mat-form-field>

    <mat-form-field
      class="stroked-select-single mr-2 mb-5"
      *ngIf="incidentsFilterService.selectedFloor && !incidentsFilterService.isRoomTable">
      <mat-label>Room</mat-label>
      <mat-select [(ngModel)]="incidentsFilterService.selectedRoom" panelClass="single-select-overlay">
        <mat-option
          *ngFor="let room of incidentsFilterService.roomOptions | sort : 'asc' : 'title' : 'all'"
          [value]="room.value">
          {{ room.title }}
        </mat-option>
      </mat-select>
      <mat-icon matSuffix class="arrow-down">keyboard_arrow_down</mat-icon>
      <mat-icon matSuffix class="arrow-up">keyboard_arrow_up</mat-icon>
    </mat-form-field>

    <button
      class="font-nunito text-sm text-ui-dark font-normal underline h-9 ml-3 test-clear-all-open-incidents-filters"
      (click)="incidentsFilterService.clearOpenIncidentsFilters()"
      *ngIf="incidentsFilterService.selectedFilters">
      Clear All
    </button>
  </div>

  <ng-container *ngTemplateOutlet="columnSelect"></ng-container>
</div>
<div
  class="test-resolved-incidents-filters flex items-start justify-between mt-5"
  *ngIf="incidentsFilterService.incidentTabStatusFilter === incidentTabStatusFilterEnum.HISTORY">
  <div class="flex flex-wrap">
    <mat-form-field class="date-range-picker mr-2" (click)="resolvedPicker.open()">
      <mat-label>Enter a date range</mat-label>
      <mat-date-range-input [rangePicker]="resolvedPicker" [formGroup]="incidentsFilterService.resolvedDateRange">
        <input [readonly]="true" matStartDate formControlName="start" placeholder="mm/dd/yyyy" />
        <input [readonly]="true" matEndDate formControlName="end" placeholder="mm/dd/yyyy" />
      </mat-date-range-input>
      <!-- <mat-hint>MM/DD/YYYY – MM/DD/YYYY</mat-hint> -->
      <mat-datepicker-toggle matIconSuffix [for]="resolvedPicker" />
      <mat-date-range-picker #resolvedPicker (closed)="incidentsFilterService.calendarClose()" />
    </mat-form-field>
    <mat-spinner matSuffix *ngIf="resolvedIncidentsLoading" class="mr-2" diameter="36" color="primary" />

    <span
      class="mr-2 h-9 border-l border-ui-gray-light"
      *ngIf="dropdownFilters && incidentsFilterService.resolvedIncidentsData.length"></span>

    <app-multiple-select
      *ngIf="dropdownFilters && incidentsFilterService.resolvedIncidentsData.length"
      [value]="incidentsFilterService.resolvedIncidentsDeviceTypeValue"
      [options]="incidentsFilterService.resolvedIncidentsDeviceTypeOptions"
      label="Device Type"
      selectClass="stroked-select-multiple"
      panelClass="device-type-overlay"
      class="mr-2"
      (selectDataEvent)="incidentsFilterService.multiSelectChange('resolvedIncidentsDeviceTypeValue', $event)" />

    <mat-form-field
      class="stroked-select-single mr-2 mb-5"
      *ngIf="
        dropdownFilters &&
        !incidentsFilterService.isBuildingTable &&
        incidentsFilterService.resolvedIncidentsData.length
      ">
      <mat-label>Building</mat-label>
      <mat-select
        [(ngModel)]="incidentsFilterService.resolvedIncidentsSelectedBuilding"
        panelClass="single-select-overlay">
        <mat-option
          *ngFor="
            let building of incidentsFilterService.resolvedIncidentsBuildingOptions | sort : 'asc' : 'title' : 'all'
          "
          [value]="building.value">
          {{ building.title }}
        </mat-option>
      </mat-select>
      <mat-icon matSuffix class="arrow-down">keyboard_arrow_down</mat-icon>
      <mat-icon matSuffix class="arrow-up">keyboard_arrow_up</mat-icon>
    </mat-form-field>

    <mat-form-field
      class="stroked-select-single mr-2 mb-5"
      *ngIf="incidentsFilterService.resolvedIncidentsSelectedBuilding && !incidentsFilterService.isFloorTable">
      <mat-label>Floor</mat-label>
      <mat-select
        [(ngModel)]="incidentsFilterService.resolvedIncidentsSelectedFloor"
        panelClass="single-select-overlay">
        <mat-option
          *ngFor="let floor of incidentsFilterService.resolvedIncidentsFloorOptions | sort : 'asc' : 'title' : 'all'"
          [value]="floor.value">
          {{ floor.title }}
        </mat-option>
      </mat-select>
      <mat-icon matSuffix class="arrow-down">keyboard_arrow_down</mat-icon>
      <mat-icon matSuffix class="arrow-up">keyboard_arrow_up</mat-icon>
    </mat-form-field>

    <mat-form-field
      class="stroked-select-single mr-2 mb-5"
      *ngIf="incidentsFilterService.resolvedIncidentsSelectedFloor && !incidentsFilterService.isRoomTable">
      <mat-label>Room</mat-label>
      <mat-select [(ngModel)]="incidentsFilterService.resolvedIncidentsSelectedRoom" panelClass="single-select-overlay">
        <mat-option
          *ngFor="let room of incidentsFilterService.resolvedIncidentsRoomOptions | sort : 'asc' : 'title' : 'all'"
          [value]="room.value">
          {{ room.title }}
        </mat-option>
      </mat-select>
      <mat-icon matSuffix class="arrow-down">keyboard_arrow_down</mat-icon>
      <mat-icon matSuffix class="arrow-up">keyboard_arrow_up</mat-icon>
    </mat-form-field>

    <button
      class="font-nunito text-sm text-ui-dark font-normal underline h-9 ml-3 test-clear-all-resolved-incidents-filters"
      mat-button
      (click)="incidentsFilterService.clearResolvedIncidentsFilters()"
      *ngIf="incidentsFilterService.resolvedIncidentsSelectedFilters">
      Clear All
    </button>
  </div>

  <ng-container *ngTemplateOutlet="columnSelect"></ng-container>
</div>

<ng-template #columnSelect>
  <mat-form-field class="column-select">
    <mat-label matTooltip="Column Select"><i class="icon-ic_column text-3xl text-ui-gray-100"></i></mat-label>
    <mat-select
      panelClass="multi-select-overlay column-select-overlay"
      #columnSelectRef
      (selectionChange)="incidentsFilterService.columnsChange($event)"
      [formControl]="incidentsFilterService.columnSelect"
      multiple>
      <div class="options-action-wrapper">
        <button class="options-action-button" (click)="selectAllColumns()">Select All</button>
      </div>
      <mat-option *ngFor="let option of incidentsFilterService.columnOptions" [value]="option.value">{{
        option.title
      }}</mat-option>
    </mat-select>
  </mat-form-field>
</ng-template>
