import { DeviceChart } from '@models';
import { getUniqueArrayItemsByProperty } from './app-variables';

const chartSpacing = [16, 24, 8, 24];

const exportingConfig = {
  exporting: {
    buttons: {
      contextButton: {
        y: -8,
        menuItems: ['viewFullscreen', 'separator', 'downloadPNG', 'downloadJPEG', 'downloadPDF', 'downloadSVG'],
        titleKey: '',
      },
    },
    fallbackToExportServer: false
  },
  credits: {
    enabled: false,
  }
}

export const createArbitraryValueChart = (chart: DeviceChart) => {
  const seriesData: Array<[number, number]> = getUniqueArrayItemsByProperty(chart.timeseries, 'timeStamp')
    .sort((a, b) => new Date(a.timeStamp).getTime() - new Date(b.timeStamp).getTime())
    .map(({ timeStamp, value }) => {
      return [Date.parse(timeStamp), Number(value)];
    });

  return {
    chart: {
      zoomType: 'x',
      spacing: chartSpacing,
    },
    title: {
      text: chart.friendlyName || chart.name,
      align: 'left',
    },
    accessibility: {
      screenReaderSection: {
        beforeChartFormat:
          '<{headingTagName}>{chartTitle}</{headingTagName}><div>{chartSubtitle}</div><div>{chartLongdesc}</div><div>{xAxisDescription}</div><div>{yAxisDescription}</div>',
      },
    },
    tooltip: {
      valueDecimals: 2,
    },
    xAxis: {
      type: 'datetime',
    },
    yAxis: {
      title: {
        text: chart.unitOFMeasure.display,
      },
    },
    series: [
      {
        data: seriesData,
        lineWidth: 0.5,
        name: `${chart.friendlyName || chart.name} (${chart.unitOFMeasure.displayShort})`,
        step: false,
        color: '#8A91F2',
      },
    ],
    plotOptions: {
      series: {
        getExtremesFromAll: true,
        states: {
          hover: {
            enabled: false,
          },
        },
      },
    },
    exporting: {
      enabled: false,
    }
  };
};

export const createPredefinedValuesChart = (chart: DeviceChart) => {
  const values: Array<number> = [];
  const seriesData: Array<[number, number]> = getUniqueArrayItemsByProperty(chart.timeseries, 'timeStamp')
    .sort((a, b) => new Date(a.timeStamp).getTime() - new Date(b.timeStamp).getTime())
    .map(({ timeStamp, value }) => {
      const numberValue = Number(value);
      values.push(numberValue);
      return [Date.parse(timeStamp), numberValue];
    });
  const minValue = Math.min(...values);
  const maxValue = Math.max(...values);

  return {
    chart: {
      zoomType: 'x',
      spacing: chartSpacing,
    },
    title: {
      text: chart.friendlyName || chart.name,
      align: 'left',
    },
    accessibility: {
      screenReaderSection: {
        beforeChartFormat:
          '<{headingTagName}>{chartTitle}</{headingTagName}><div>{chartSubtitle}</div><div>{chartLongdesc}</div><div>{xAxisDescription}</div><div>{yAxisDescription}</div>',
      },
    },
    xAxis: {
      type: 'datetime',
    },
    yAxis: {
      title: {
        text: chart.unitOFMeasure.display,
      },
      max: maxValue !== minValue ? maxValue : undefined,
      allowDecimals: false,
    },
    series: [
      {
        data: seriesData,
        lineWidth: 1,
        name: `${chart.friendlyName || chart.name}`,
        step: true,
        color: '#8A91F2',
      },
    ],
    lang: {
      noData: 'No data found for selected period',
    },
    plotOptions: {
      series: {
        getExtremesFromAll: true,
        states: {
          hover: {
            enabled: false,
          },
        },
        marker: {
          enabled: true,
        },
      },
    },
    exporting: {
      enabled: false,
    }
  };
};

export const createMonthlyChart = (data: number[], year: number): Highcharts.Options => ({
  chart: {
    type: 'column',
    spacing: chartSpacing,
  },
  title: {
    text: `Incidents by Month (${year})`,
    align: 'left',
  },
  subtitle: {
    text: '',
  },
  xAxis: {
    categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
    crosshair: false,
  },
  yAxis: {
    min: 0,
    title: {
      text: 'Incidents',
    },
    allowDecimals: false,
  },
  tooltip: {
    enabled: true,
    formatter: function () {
      return this.x + '<br/>Incidents: ' + '<b>' + this.y + '</b>';
    },
  },
  series: [
    {
      type: 'column',
      data,
      color: '#8A91F2',
    },
  ],
  ...exportingConfig,
});

export const createIncidentStatusChart = ({
  resolved,
  inQueue,
  inProgress,
}: {
  resolved: number;
  inQueue: number;
  inProgress: number;
}): Highcharts.Options => {
  const data = [];
  if (resolved) {
    data.push({
      name: 'RESOLVED',
      y: resolved,
      color: '#14B8A6',
    });
  }
  if (inQueue) {
    data.push({
      name: 'IN QUEUE',
      y: inQueue,
      color: '#FE5A5A',
    });
  }
  if (inProgress) {
    data.push({
      name: 'IN PROGRESS',
      y: inProgress,
      color: '#ffdc24',
    });
  }

  return {
    chart: {
      plotShadow: false,
      type: 'pie',
      spacing: chartSpacing,
    },
    title: {
      text: 'Incidents by Incident Status',
      align: 'left',
    },
    tooltip: {
      enabled: false,
    },
    plotOptions: {
      pie: {
        allowPointSelect: true,
        cursor: 'pointer',
        dataLabels: {
          enabled: true,
          format: '{point.name}<br/> {point.y}',
          style: {
            color: 'black',
          },
        },
      },
    },
    series: [
      {
        name: 'Incidents',
        type: 'pie',
        data,
      },
    ],
    ...exportingConfig
  };
};

export const createDeviceModelChart = (
  deviceModels: Array<{ id: string; modelName: string; manufacturerName: string; count: number }>
): Highcharts.Options => {
  const categoryHeight = 10;
  const categoryPadding = 5;
  const chartContentHeight = 80;
  const categories = deviceModels.map(deviceModel => `${deviceModel.modelName} (${deviceModel.manufacturerName})`);
  const seriesData = deviceModels.map(deviceModel => deviceModel.count);
  return {
    chart: {
      type: 'bar',
      height: chartContentHeight + deviceModels.length * (categoryHeight + 2 * categoryPadding),
      spacing: chartSpacing,
    },
    title: {
      text: 'Incidents by Device Model',
      align: 'left',
    },
    subtitle: {
      text: '',
    },
    legend: {
      enabled: true,
      layout: 'vertical',
      align: 'right',
      verticalAlign: 'middle',
    },
    xAxis: {
      categories,
      crosshair: false,
    },
    yAxis: {
      min: 0,
      title: {
        text: 'Incidents',
      },
      allowDecimals: false,
    },
    tooltip: {
      enabled: true,
      formatter: function () {
        return this.x + '<br/>Incidents: ' + '<b>' + this.y + '</b>';
      },
    },
    series: [
      {
        showInLegend: false,
        type: 'bar',
        data: seriesData,
        color: '#8A91F2',
      },
    ],
    ...exportingConfig
  };
};

export const createIncidentManufacturerChart = (data: Array<{ name: string; y: number }>): Highcharts.Options => {
  return {
    chart: {
      plotShadow: false,
      type: 'pie',
      spacing: chartSpacing
    },
    title: {
      text: 'Open Incidents by Manufacturer',
      align: 'left',
    },
    tooltip: {
      enabled: false,
    },
    plotOptions: {
      pie: {
        allowPointSelect: true,
        cursor: 'pointer',
        dataLabels: {
          enabled: true,
          format: '{point.name}<br/> {point.y}',
          style: {
            color: 'black',
          },
        },
      },
    },
    series: [
      {
        name: 'Incidents',
        type: 'pie',
        data,
      },
    ],
    ...exportingConfig
  };
};