@if (!dataIsLoading) {
<div class="overflow-auto" [ngClass]="{ 'fixed-scroll': isInjected }">
  <table mat-table #table [dataSource]="dataSource" class="main-table" matSort id="incidents-table">
    <!--- Note that these columns can be defined in any order.
                  The actual rendered columns are set as a property on the row definition" -->

    <!-- Number Column -->
    <ng-container [matColumnDef]="IncidentColumn.INDEX" [sticky]="!isMobile">
      <th mat-header-cell *matHeaderCellDef>{{ staticIncidentColumnTitle[IncidentColumn.INDEX] }}</th>
      <td mat-cell *matCellDef="let index = index">
        {{ index + 1 }}
      </td>
    </ng-container>

    <!-- Ticket Number Column -->
    <ng-container [matColumnDef]="IncidentColumn.TICKET_NUMBER" [sticky]="!isMobile">
      <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by ticker number">
        {{ staticIncidentColumnTitle[IncidentColumn.TICKET_NUMBER] }}
      </th>
      <td mat-cell *matCellDef="let element">
        <a [class.underline]="!isReportMode" [routerLink]="isReportMode ? null : ['/incidents', element.id]">{{
          element.ticketNumber
        }}</a>
      </td>
    </ng-container>

    <!-- Status Column -->
    <ng-container [matColumnDef]="IncidentColumn.STATUS">
      <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by status">
        {{ incidentColumnTitle[IncidentColumn.STATUS] }}
      </th>
      <td mat-cell *matCellDef="let element">
        <app-incident-status [status]="element.status"></app-incident-status>
      </td>
    </ng-container>

    <!-- Device Name Column -->
    <ng-container [matColumnDef]="IncidentColumn.DEVICE_ID">
      <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by device name">
        {{ incidentColumnTitle[IncidentColumn.DEVICE_ID] }}
      </th>
      <td mat-cell *matCellDef="let element">
        <a
          [class.underline]="!isReportMode"
          [routerLink]="isReportMode ? null : ['/devices', element.buildingId, element.deviceId]">
          {{ element.deviceName }}
        </a>
      </td>
    </ng-container>

    <!-- Device Type Column -->
    <ng-container [matColumnDef]="IncidentColumn.DEVICE_TYPE">
      <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by device type">
        {{ incidentColumnTitle[IncidentColumn.DEVICE_TYPE] }}
      </th>
      <td mat-cell *matCellDef="let element">{{ element.deviceType }}</td>
    </ng-container>

    <!-- Building Name Column -->
    <ng-container [matColumnDef]="IncidentColumn.BUILDING">
      <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by building name">
        {{ incidentColumnTitle[IncidentColumn.BUILDING] }}
      </th>
      <td mat-cell *matCellDef="let element">
        <a [class.underline]="!isReportMode" [routerLink]="isReportMode ? null : ['/dashboard', element.buildingId]">{{
          element.building
        }}</a>
      </td>
    </ng-container>

    <!-- Floor Column -->
    <ng-container [matColumnDef]="IncidentColumn.FLOOR">
      <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by floor">
        {{ incidentColumnTitle[IncidentColumn.FLOOR] }}
      </th>
      <td mat-cell *matCellDef="let element">
        <a
          [class.underline]="!isReportMode"
          [routerLink]="isReportMode ? null : ['/dashboard', element.buildingId, element.floorId]"
          >{{ element.floor }}</a
        >
      </td>
    </ng-container>

    <!-- Room Column -->
    <ng-container [matColumnDef]="IncidentColumn.ROOM">
      <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by room">
        {{ incidentColumnTitle[IncidentColumn.ROOM] }}
      </th>
      <td mat-cell *matCellDef="let element">
        <a
          [class.underline]="!isReportMode"
          [routerLink]="isReportMode ? null : ['/dashboard', element.buildingId, element.floorId, element.roomId]"
          >{{ element.room }}</a
        >
      </td>
    </ng-container>

    <!-- Manufacturer Column -->
    <ng-container [matColumnDef]="IncidentColumn.MANUFACTURER">
      <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by manufacturer">
        {{ incidentColumnTitle[IncidentColumn.MANUFACTURER] }}
      </th>
      <td mat-cell *matCellDef="let element">{{ element.manufacturer }}</td>
    </ng-container>

    <!-- Model Column -->
    <ng-container [matColumnDef]="IncidentColumn.MODEL">
      <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by model">
        {{ incidentColumnTitle[IncidentColumn.MODEL] }}
      </th>
      <td mat-cell *matCellDef="let element">{{ element.model }}</td>
    </ng-container>

    <!-- Serial Number Column -->
    <ng-container [matColumnDef]="IncidentColumn.SERIAL_NUMBER">
      <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by serial number">
        {{ incidentColumnTitle[IncidentColumn.SERIAL_NUMBER] }}
      </th>
      <td mat-cell *matCellDef="let element">{{ element.serialNumber }}</td>
    </ng-container>

    <!-- Reported Column -->
    <ng-container [matColumnDef]="IncidentColumn.REPORTED">
      <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by reported">
        {{ incidentColumnTitle[IncidentColumn.REPORTED] }}
      </th>
      <td mat-cell *matCellDef="let element">{{ element.reported }}</td>
    </ng-container>

    <!-- Faultname Column -->
    <ng-container [matColumnDef]="IncidentColumn.FAULT_NAME">
      <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by fault name">
        {{ incidentColumnTitle[IncidentColumn.FAULT_NAME] }}
      </th>
      <td mat-cell *matCellDef="let element">{{ element.faultname }}</td>
    </ng-container>

    <!-- Resolved Column -->
    <ng-container [matColumnDef]="IncidentColumn.RESOLVED">
      <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by resolved">
        {{ incidentColumnTitle[IncidentColumn.RESOLVED] }}
      </th>
      <td mat-cell *matCellDef="let element">{{ element.resolved || '-' }}</td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="incidentsFilterService.displayedColumns; sticky: true"></tr>
    <tr mat-row *matRowDef="let row; columns: incidentsFilterService.displayedColumns"></tr>
  </table>
</div>

<app-no-data
  *ngIf="!dataSource.filteredData.length"
  [noDataText]="
    incidentsFilterService.incidentTabStatusFilter === incidentTabStatusFilterEnum.HISTORY
      ? 'No resolved incidents for selected period'
      : noDataText
  "
  [resultContent]="!!dataSource.data.length"
  [hasBigPadding]="isNoDataBigSize" />
} @else {
<app-skeleton-table [showTabs]="false" [showFilters]="false" />
}
