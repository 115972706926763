<mat-card class="card h-full px-3">
  <div class="border-b-ui-gray-light border-b flex mb-2">
    <mat-button-toggle-group
      [value]="periodFilterValue"
      class="desktop-toggle-group"
      name="period"
      [disabled]="isLoading"
      (change)="changePeriod($event)"
      aria-label="period of time">
      <mat-button-toggle [value]="ReportYear.CURRENT">
        <span class="item-status-title">Current Year</span>
      </mat-button-toggle>
      <mat-button-toggle [value]="ReportYear.PREVIOUS">
        <span class="item-status-title">Previous Year</span>
      </mat-button-toggle>
    </mat-button-toggle-group>
  </div>
  @if (!isLoading) {
  <highcharts-chart
    *ngIf="incidentMonthChartData"
    class="highcharts-no-legend highcharts-small-title highcharts-classic-axes"
    [Highcharts]="Highcharts"
    [options]="incidentMonthChartData" />
  } @else {
  <ngx-skeleton-loader [theme]="{ height: '400px' }" />
  }

  <div *ngIf="!incidentMonthChartData" class="h-[400px]"></div>
</mat-card>
