import { Component, ViewChild } from '@angular/core';
import { MatFormField, MatOption, MatSelect } from '@angular/material/select';
import { DevicesFilterService } from '@services';
import { NgForOf, NgIf } from '@angular/common';
import { MatButtonToggle, MatButtonToggleGroup } from '@angular/material/button-toggle';
import { SearchInputComponent } from '@standalone/search-input/search-input.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatLabel } from '@angular/material/form-field';
import { MultipleSelectComponent } from '@standalone/multiple-select/multiple-select.component';
import { MatIcon } from '@angular/material/icon';
import { PipesModule } from '@pipes';
import { MatTooltip } from '@angular/material/tooltip';

@Component({
  standalone: true,
  selector: 'app-devices-table-desktop-filters',
  templateUrl: './devices-table-desktop-filters.component.html',
  imports: [
    NgIf,
    MatLabel,
    MatButtonToggleGroup,
    MatButtonToggle,
    SearchInputComponent,
    MatFormField,
    MatSelect,
    ReactiveFormsModule,
    MatOption,
    NgForOf,
    MultipleSelectComponent,
    FormsModule,
    MatIcon,
    PipesModule,
    MatTooltip,
  ],
})
export class DevicesTableDesktopFiltersComponent {
  @ViewChild('columnSelectRef') columnSelectRef!: MatSelect;
  constructor(public devicesFilterService: DevicesFilterService) {}

  selectAllColumns() {
    this.columnSelectRef.options.forEach((option: MatOption) => option.select());
  }
}
