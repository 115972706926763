@let isIncidentLoaded = (isIncidentLoaded$ | async);
<mat-card class="card h-full px-3 justify-end">
  @if (isIncidentLoaded) {
  <highcharts-chart
    *ngIf="incidentManufacturerChartData"
    class="highcharts-no-legend highcharts-small-title highcharts-classic-axes"
    [Highcharts]="Highcharts"
    [options]="incidentManufacturerChartData" />
  } @else {
  <ngx-skeleton-loader [theme]="{ height: '400px' }" />
  }
</mat-card>
