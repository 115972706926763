import {
  Component,
  DestroyRef,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
  ViewChild,
  inject,
  ChangeDetectorRef,
} from '@angular/core';
import {
  MatCell,
  MatCellDef,
  MatColumnDef,
  MatHeaderCell,
  MatHeaderCellDef,
  MatHeaderRow,
  MatHeaderRowDef,
  MatRow,
  MatRowDef,
  MatTable,
  MatTableDataSource,
} from '@angular/material/table';
import { EntityStatus, IncidentStatus, RoomSpaceModel, Space, UserRolesIds } from '@models';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort, MatSortHeader } from '@angular/material/sort';
import { MatDialog } from '@angular/material/dialog';
import { Action, Store } from '@ngrx/store';
import { AppState, updateRoom, userRole } from '@ngrx-store';
import { MatButtonToggle, MatButtonToggleChange, MatButtonToggleGroup } from '@angular/material/button-toggle';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { naturalSorting } from '@app-lib';
import { Router, RouterLink } from '@angular/router';
import { AddExternalLinkModalComponent } from '@standalone/_modals/add-external-link-modal.component/add-external-link-modal.component';
import { ConfirmationDialogComponent } from '@standalone/_modals/confirmation-dialog/confirmation-dialog.component';
import { MatCardContent, MatCardHeader, MatCardTitle } from '@angular/material/card';
import { DirectivesModule } from '@directives';
import { MatIcon } from '@angular/material/icon';
import { MatPrefix } from '@angular/material/form-field';
import { NgClass, NgIf } from '@angular/common';
import { SearchInputComponent } from '@standalone/search-input/search-input.component';
import { IncidentStatusCountComponent } from '@standalone/incident-status-count/incident-status-count.component';
import { DeviceStatusCountComponent } from '@standalone/device-status-count/device-status-count.component';
import { MatTooltip } from '@angular/material/tooltip';
import { NoDataComponent } from '@standalone/no-data/no-data.component';
import { EntityStatusComponent } from '@standalone/entity-status/entity-status.component';

@Component({
  standalone: true,
  selector: 'app-room-list-table',
  templateUrl: './room-list-table.component.html',
  styleUrl: './room-list-table.component.scss',
  imports: [
    MatCardTitle,
    DirectivesModule,
    RouterLink,
    MatIcon,
    MatPrefix,
    NgIf,
    MatCardHeader,
    MatButtonToggleGroup,
    MatButtonToggle,
    MatCardContent,
    SearchInputComponent,
    MatSort,
    MatTable,
    MatColumnDef,
    MatHeaderCell,
    MatHeaderCellDef,
    MatCell,
    MatCellDef,
    IncidentStatusCountComponent,
    DeviceStatusCountComponent,
    MatTooltip,
    NoDataComponent,
    MatRowDef,
    MatHeaderRowDef,
    MatPaginator,
    NgClass,
    EntityStatusComponent,
    MatSortHeader,
    MatHeaderRow,
    MatRow,
  ],
})
export class RoomListTableComponent implements OnInit, OnChanges {
  @ViewChild(MatSort) sort!: MatSort;
  @Input() locationId: string | undefined;
  @Input() floorId: string | undefined;
  @Input() roomList: RoomSpaceModel[] = [];
  @Input() isMobile = false;
  destroyRef = inject(DestroyRef);
  userRoleId = '';
  displayedColumns: string[] = ['position', 'room', 'status', 'incidents', 'devices', 'actions'];
  dataSource: MatTableDataSource<Space> = new MatTableDataSource<Space>([]);
  entityStatus = EntityStatus;
  incidentStatus = IncidentStatus;
  statusFilter: 'open' | 'history' = 'open';
  textSearchFilter = '';

  @ViewChild('tableContent') set paginatorBlock(element: MatTable<any>) {
    if (element) {
      this.dataSource.sort = this.sort;
      this.changeDetectionRef.detectChanges();
    }
  }

  constructor(
    private dialog: MatDialog,
    private store: Store<AppState>,
    private router: Router,
    private dialogService: MatDialog,
    private changeDetectionRef: ChangeDetectorRef
  ) {
    this.store
      .select(userRole)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(roleId => (this.userRoleId = roleId));
  }

  get showActionColumn() {
    return this.userRoleId === UserRolesIds.ReadOnly ? this.roomList.some(room => room.externalReference) : true;
  }

  ngOnInit(): void {
    this.dataSourceInit();
  }

  // Hook. Forced update of the filter model when changes occurred
  filterRefresh() {
    this.dataSource.filter = Math.random().toString();
  }

  filterPredicate = (data: RoomSpaceModel) => {
    let filterMatch = true;
    if (this.textSearchFilter) {
      const strIndex = data.friendlyName.toLowerCase().indexOf(this.textSearchFilter.toLowerCase().trim());
      filterMatch = strIndex !== -1;
    }
    if (this.statusFilter && filterMatch) {
      filterMatch =
        this.statusFilter !== 'history' ? data.status !== EntityStatus.Archived : data.status === EntityStatus.Archived;
    }
    return filterMatch;
  };

  tableSearch($event: string) {
    this.textSearchFilter = $event;
    this.filterRefresh();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['roomList']) {
      this.dataSourceInit();
    }
  }

  dataSourceInit() {
    this.dataSource = new MatTableDataSource<Space>(this.roomList);
    this.dataSource.filterPredicate = this.filterPredicate;
    this.filterRefresh();
    this.dataSource.sortData = naturalSorting;
  }

  openConfirmationDialog({ action, title, description }: { title: string; description: string; action: Action }) {
    ConfirmationDialogComponent.open(this.dialog, {
      title,
      description,
    })
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(confirmation => {
        if (confirmation) {
          this.store.dispatch(action);
        }
      });
  }

  updateRoomStatus(room: RoomSpaceModel, title: string, status: EntityStatus) {
    if (this.locationId) {
      const statusActionText: 'resume' | 'pause' | 'archive' =
        status === EntityStatus.Active ? 'resume' : status === EntityStatus.Paused ? 'pause' : 'archive';
      this.openConfirmationDialog({
        title,
        description: `Are you sure you want to ${statusActionText} the ${room.friendlyName}?`,
        action: updateRoom({
          locationId: this.locationId,
          spaceId: room.id,
          data: { status },
        }),
      });
    }
  }

  onStatusFilterChange($event: MatButtonToggleChange) {
    this.statusFilter = $event.value;
    this.filterRefresh();
  }

  editFloor(room: RoomSpaceModel) {
    if (!this.isMobile) {
      this.router.navigate(['/dashboard', this.locationId, this.floorId, room.id, 'edit']);
      return;
    }
    this.dialogService.open(AddExternalLinkModalComponent, {
      data: {
        link: room.externalReference,
        locationId: this.locationId,
        spaceId: room.id,
      },
    });
  }
}
