<mat-card class="card py-0" [ngClass]="{ 'bg-transparent p-0 shadow-none': isInjected || isMobile }">
  <mat-card-content class="py-6" [ngClass]="{ 'bg-transparent p-0': isInjected || isMobile }">
    @let userListLoaded = userListLoaded$ |async ; @if (userListLoaded){
    <div class="mb-3">
      <div [class]="isMobile ? 'relative' : 'border-b-ui-gray-light border-b flex justify-between items-center'">
        <mat-button-toggle-group
          [class]="isMobile ? 'mobile-main-toggle-group mb-3' : 'desktop-toggle-group'"
          name="incident-status"
          aria-label="Incident Status"
          [value]="userStatusFilter"
          (valueChange)="onStatusFilterChange($event)">
          <mat-button-toggle value="current" class="test-incident-status-open">
            <span class="item-status-title">Current</span>
          </mat-button-toggle>
          <mat-button-toggle value="archived" class="test-incident-status-history">
            <span class="item-status-title">Archived</span>
          </mat-button-toggle>
        </mat-button-toggle-group>
        <a
          *ngIf="isMobile"
          appRestrictedEl
          [showForClientAdmin]="true"
          class="btn-green btn-medium w-10 absolute right-0 top-0 p-0"
          routerLink="add-new-user">
          <mat-icon fontIcon="add" />
        </a>
        <app-search-input
          [class]="isMobile ? 'block w-full' : ''"
          placeholder="Enter User Name or E-mail"
          (searchDataEvent)="userSearch($event)"
          [inputWidth]="isMobile ? '100%' : '256px'" />
      </div>
    </div>
    <div class="overflow-auto" [ngClass]="{ 'fixed-scroll': isInjected }" [hidden]="!userListLoaded">
      <table
        matSortActive="firstName"
        matSortDirection="asc"
        mat-table
        matSort
        id="users-table"
        class="main-table"
        #tableContent
        [dataSource]="dataSource">
        <!-- Number Column -->
        <ng-container matColumnDef="index">
          <th mat-header-cell *matHeaderCellDef>№</th>
          <td mat-cell *matCellDef="let index = index">
            <!--          {{ paginatorRef.pageIndex * paginatorRef.pageSize + (index + 1) }}-->
            {{ index + 1 }}
          </td>
        </ng-container>

        <!-- User Name Column -->
        <ng-container matColumnDef="firstName">
          <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by User Name">Name</th>
          <td mat-cell *matCellDef="let element">
            <a class="underline" [routerLink]="['/user-management', element.id]">
              {{ element.firstName }} {{ element.lastName }}
            </a>
          </td>
        </ng-container>

        <!-- Status Column -->
        <ng-container matColumnDef="status">
          <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by status">Status</th>
          <td mat-cell *matCellDef="let element">
            <app-user-status [status]="element.status" />
          </td>
        </ng-container>

        <!-- Role Column -->
        <ng-container matColumnDef="role">
          <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by role">Role</th>
          <td mat-cell *matCellDef="let element">
            {{ element?.role?.name | fixRoleName }}
          </td>
        </ng-container>

        <!-- Email Column -->
        <ng-container matColumnDef="email">
          <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by email">Email</th>
          <td mat-cell *matCellDef="let element">
            {{ element.email }}
          </td>
        </ng-container>

        <!-- Phone Column -->
        <ng-container matColumnDef="phoneNumber">
          <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by phone">Phone</th>
          <td mat-cell *matCellDef="let element" class="text-center text-nowrap">
            @if (element?.phone.number) {
            {{ element.phone.number | formatPhoneNumber : element.phone.countryCode : true }}
            } @else { - }
          </td>
        </ng-container>

        <!-- Last login Column -->
        <ng-container matColumnDef="lastLogin">
          <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by Last login">
            Last login
          </th>
          <td mat-cell *matCellDef="let element">
            <span *ngIf="element.lastLogin">{{ element.lastLogin | date : 'MM/dd/yy - hh:mm a' }}</span>
            <span *ngIf="!element.lastLogin">Never</span>
          </td>
        </ng-container>

        <!-- Archive Column -->
        <ng-container matColumnDef="archive">
          <th mat-header-cell *matHeaderCellDef appRestrictedEl [showForClientAdmin]="true"></th>
          <td mat-cell *matCellDef="let element" appRestrictedEl [showForClientAdmin]="true">
            <ng-container *ngIf="editor$ | async as editor">
              <div *ngIf="editor.id !== element.id" class="flex items-center justify-end">
                <i
                  *ngIf="element.status === ClientUserStatus.INVITED"
                  class="icon-resend-email action-icon mr-2"
                  (click)="resendInvitation(element)"
                  matTooltip="Resend Invitation"></i>
                <i
                  *ngIf="userStatusFilter !== ClientUserStatus.ARCHIVED"
                  class="icon-add-to-archive action-icon"
                  (click)="archiveUser(element)"
                  matTooltip="Archive"></i>
                <i
                  *ngIf="userStatusFilter === ClientUserStatus.ARCHIVED"
                  class="action-icon icon-restore"
                  (click)="restoreUser(element)"
                  matTooltip="Restore"></i>
              </div>
            </ng-container>
          </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
      </table>
      <app-no-data *ngIf="!dataSource.filteredData.length" [resultContent]="!!dataSource.data.length" />
    </div>
    } @else {
    <app-skeleton-table />
    }
  </mat-card-content>
</mat-card>
