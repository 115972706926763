<app-page-header>Reporting</app-page-header>

<div class="flex items-center justify-between mt-4">
  <mat-button-toggle-group [(ngModel)]="reportingType" class="main-toggle-group" aria-label="type of reports">
    <mat-button-toggle class="border-ui-green-light" value="incident">
      <mat-icon matIconPrefix *ngIf="reportingType === 'incident'">check</mat-icon>
      Incident
    </mat-button-toggle>
    <mat-button-toggle class="border-ui-green-light" value="firmware">
      <mat-icon matIconPrefix *ngIf="reportingType === 'firmware'">check</mat-icon>
      Firmware
    </mat-button-toggle>
  </mat-button-toggle-group>
  <app-export-report-menu
    *ngIf="reportingType === 'firmware'"
    [disabled]="!devicesFilterService.filteredDevices.length"
    (exportDataType)="exportDevices($event)" />
  <app-export-report-menu
    *ngIf="reportingType === 'incident'"
    [disabled]="!incidents?.length"
    (exportDataType)="exportIncidents($event)" />
</div>

<div [hidden]="reportingType !== 'incident'" class="test-incident-report-filters">
  <mat-card class="bg-white card mt-4">
    <mat-card-content class="p-0">
      <app-report-filters
        [reportingType]="reportingType"
        (reportTypeEvent)="reportingType = $event"
        (exportIncidents)="setIncidents($event)"></app-report-filters>
    </mat-card-content>
  </mat-card>
</div>

<div [hidden]="!(reportingType === 'incident' && incidents)" class="test-incident-table">
  <app-incident-charts [incidents]="incidents" />
  <mat-card class="card py-0">
    <mat-card-title class="card-title-small test-table-title border-b-ui-gray-light border-b py-3.5 mb-3"
      >Incident List
    </mat-card-title>
    <mat-card-content class="p-0">
      <app-incidents-table [incidentsData]="incidents || []" [isReportMode]="true" />
    </mat-card-content>
  </mat-card>
</div>

<div [hidden]="reportingType !== 'firmware'" class="test-firmware-table">
  <mat-card class="card pt-0 mt-4">
    <mat-card-title class="card-title-small test-table-title border-b-ui-gray-light border-b py-3.5"
      >Firmware List
    </mat-card-title>
    <mat-card-content *ngIf="deviceListLoaded$ | async; else spinner" class="p-0">
      <app-devices-table-desktop-filters />
      <app-devices-table />
    </mat-card-content>
  </mat-card>
  <ng-template #spinner>
    <app-skeleton-table />
  </ng-template>
</div>

<div [hidden]="reportingType !== 'incident'" class="test-default-charts">
  <div class="grid grid-cols-2 gap-4 my-4 mx-auto">
    <app-incident-manufacturer-chart />
    <app-incidents-monthly-chart />
  </div>
</div>
